@media screen and (max-width: 800px) {
  .cardContainer {
    flex-direction: column;
  }

  body .container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    -moz-transform: none;
    -webkit-transform: none;
  }
}

@media screen and (max-width: 960px) {
  .container {
    margin: 10px;
  }
}

.App {
  text-align: center;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.cardContainer {
  display: flex;
}

.cardContainer_gift {
  flex-wrap: wrap;
}

.cardWrapper {
  margin: 10px;
  flex-basis: 100%;
}

.cardWrapper_gift {
  margin: 10px;
  flex: 0 0 33%;
}

.card {
  text-align: center;
  padding: 10px;

  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;

  text-decoration: none;

  border: 1px solid blue;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

  transform: translateY(0);
  box-shadow:
    0 0 5px  #ffffff41,
    -10px 0 20px #ff00ff41,
    10px 0 20px #00ffff41;
}